import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Gallery = () => {
  return (
    <div>
        <Header />

    {/* <!-- banner section start start--> */}
    <div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Gallery</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Gallery</li>
      </ol>
    </nav>
  </div>
</div>

<div className="main-gallery float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-4">
        <div className="portfolio_img_wrapper">
          <div className="portfolio_img">
            <img src="images/1.jpg" className="img-responsive" alt="team1_img" />
            <div className="portfolio_img_overlay overlay-color">
              <div className="portfolio_img_text">
                <Link to="images/1.jpg" title="image1" className="img-link">
                  <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="portfolio_img_wrapper">
          <div className="portfolio_img">
            <img src="images/2.jpg" className="img-responsive" alt="team1_img" />
            <div className="portfolio_img_overlay overlay-color">
              <div className="portfolio_img_text">
                <Link to="images/2.jpg" title="image2" className="img-link">
                  <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="portfolio_img_wrapper">
          <div className="portfolio_img">
            <img src="images/3.jpg" className="img-responsive" alt="team1_img" />
            <div className="portfolio_img_overlay overlay-color">
              <div className="portfolio_img_text">
                <Link to="images/3.jpg" title="image3" className="img-link">
                  <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="portfolio_img_wrapper">
          <div className="portfolio_img">
            <img src="images/4.jpg" className="img-responsive" alt="team1_img" />
            <div className="portfolio_img_overlay overlay-color">
              <div className="portfolio_img_text">
                <Link to="images/4.jpg" title="image4" className="img-link">
                  <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="portfolio_img_wrapper">
          <div className="portfolio_img">
            <img src="images/5.jpg" className="img-responsive" alt="team1_img" />
            <div className="portfolio_img_overlay overlay-color">
              <div className="portfolio_img_text">
                <Link to="images/5.jpg" title="image5" className="img-link">
                  <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="portfolio_img_wrapper">
          <div className="portfolio_img">
            <img src="images/6.jpg" className="img-responsive" alt="team1_img" />
            <div className="portfolio_img_overlay overlay-color">
              <div className="portfolio_img_text">
                <Link to="images/6.jpg" title="image6" className="img-link">
                  <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-12">
        <nav aria-label="Page navigation example" className="page-navigation">
          <ul className="pagination justify-content-center ">
            <li className="page-item">
              <Link className="page-link" to="#" tabIndex={-1} aria-disabled="true"><i className="fas fa-angle-left" /></Link>
            </li>
            <li className="page-item"><Link className="page-link" to="#">1</Link></li>
            <li className="page-item"><Link className="page-link" to="#">2</Link></li>
            <li className="page-item"><Link className="page-link" to="#">3</Link></li>
            <li className="page-item">
              <Link className="page-link" to="#"><i className="fas fa-angle-right" /></Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>


    {/* <!-- galllery-section --> */}
        <Footer />
    </div>
  )
}

export default Gallery