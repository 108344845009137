import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div>
        <Header />

       {/* banner-section-start  */}
<div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>About Us</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">About Us</li>
      </ol>
    </nav>
  </div>
</div>
 {/* banner-section-end  */}

 {/* about-section-start  */}
<div className="about-wrapper about-page float-left animated wow fade-up">
  <div className="container">
    <div className="row fade-up">
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="about-content py-0">
          <h5>About US</h5>
          <h3>Why We Are The Best?</h3>
          <p>It is a long established fact that a reader will be distracted by the readable content of a
            page when
            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
            distribution
            of letters, as opposed to using 'Content here, content here.</p>
          <p className="about-pera">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an unknown printer took a
            galley of
            type and scrambled it to make a type specimen book. It has survived not only five centuries,
            but also
            the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply
            dummy
            text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
            dummy
            text ever since the 1500s.</p>
          <div className="about-btn-page">
            <Link to="#" className="main-btn-red">
              <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Make An
                Appointment
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="about-img mt-lg-0 mt-md-4 mt-4">
          <span>
            <img src="images/staff-2.png" alt="img" className="img-fluid w-100" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
     {/* about-section-end  */}




     {/*  extraodinary-start */}
<div className="ordinary-about-wrapper float-left">
  <div className="container">
    <div className="ordinary-head animated wow fade-up">
      <h5>Extraordinary Experience In Nail Care</h5>
      <h3>Our Nail Salon IS THE MOST POPULAR, CLEAN
        AND RECOMMENDED NAIL SALON</h3>
    </div>
    <div className="ordinary-vdo animated wow fade-box">
      <div className="ordinary-img">
        <Link id="play-video" className="video-play-button youtube-link" to="#" youtubeid="JMJ4yrchiZ4">
          <div className="btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
            <div className="wrapper">
              <div className="waves-block">
                <div className="waves wave-1" />
                <div className="waves wave-2" />
                <div className="waves wave-3" />
              </div>
              <i className="fas fa-play" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
</div>

{/* team-section-start  */}
<div className="team-wrapper team-about float-left pb-0 ">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="team-head animated wow fade-up">
          <h5>Our Staff</h5>
          <h3>OUR PROFESSIONALS</h3>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-1.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Jordan Mike</h5>
              </Link>
              <span>Manager</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-2.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>KELLEY MILES</h5>
              </Link>
              <span>Director</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-3.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Smith Dan</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-4.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Carolyn Olson</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    {/* team-section-end */}





    {/*  testimonial-section-start */}
<div className="testimonial-wrapper float-left">
  <div className="testimonial-content-wrapper float-left">
    <div className="container">
      <div className="row">
        <div className="testimonial-head animated wow fade-up">
          <h5>Testimonial</h5>
          <h3>What our client’s say</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-9">
          <div className="test-slider-wrapper float-left animated wow fade-box">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="testimonial-box">
                  <i className="fas fa-quote-left" />
                  <div className="testimonial-content">
                    <p>
                      We provide exquisite service with some of the most talented and
                      experienced artists in the industry.
                      My team and I compliment the face using stunning hairdressing
                      techniques, from up-do’s to fishtails.
                      Whether you’re attending a red carpet event or hosting a TV show, my
                      makeup services will ensure you look flawless.
                    </p>
                  </div>
                  <img src="images/testimonial-2.png" alt="img" />
                  <h5>Pamela Adams</h5>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-box">
                  <i className="fas fa-quote-left" />
                  <div className="testimonial-content">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the 1500s, when an
                      unknown printer took a
                      galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                  <img src="images/testimonial-1.png" alt="img" />
                  <h5>Pamela Adams</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* testimonial-section-end */}


{/* news-section-start */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head animated wow fade-up">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form animated wow fade-up">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <Link to="#" className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>

{/* news-section-end  */}
        <Footer />
    </div>
  )
}

export default About