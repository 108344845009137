import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Gallery3 = () => {
  return (
    <div>
        <Header />

        {/* <!-- banner section start start--> */}
  <div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Gallery Five Column</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Gallery Five</li>
      </ol>
    </nav>
  </div>
</div>


    {/* <!-- galllery-section --> */}

   <div className="main-gallery float-left">
  <div className="container-fluid">
    <div className="row">
      <ul>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/1.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/1.jpg" title="image1" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/2.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/2.jpg" title="image2" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/3.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/3.jpg" title="image4" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/4.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/4.jpg" title="image5" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/5.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/5.jpg" title="image5" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/6.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/6.jpg" title="image1" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/7.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/7.jpg" title="image2" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/8.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/8.jpg" title="image4" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/9.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/9.jpg" title="image5" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/11.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/11.jpg" title="image5" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/12.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/12.jpg" title="image1" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/13.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/13.jpg" title="image2" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/14.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/14.jpg" title="image4" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/15.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/15.jpg" title="image5" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="portfolio_img_wrapper">
            <div className="portfolio_img">
              <img src="images/1.jpg" className="img-responsive" alt="team1_img" />
              <div className="portfolio_img_overlay overlay-color">
                <div className="portfolio_img_text">
                  <Link to="images/1.jpg" title="image5" className="img-link">
                    <img src="images/plus-Icon.png" alt="img" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <nav aria-label="Page navigation example" className="page-navigation">
        <ul className="pagination justify-content-center ">
          <li className="page-item">
            <Link className="page-link" to="#" tabIndex={-1}><i className="fas fa-angle-left" /></Link>
          </li>
          <li className="page-item"><Link className="page-link" to="#">1</Link></li>
          <li className="page-item"><Link className="page-link" to="#">2</Link></li>
          <li className="page-item"><Link className="page-link" to="#">3</Link></li>
          <li className="page-item">
            <Link className="page-link" to="#"><i className="fas fa-angle-right" /></Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>



        <Footer />
    </div>
  )
}

export default Gallery3