import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <div>
        <Header />




        {/* <!-- banner-section-start --> */}
<div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Blog</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Blog</li>
      </ol>
    </nav>
  </div>
</div>



{/* <!-- banner-section-end --> */}

{/* <!-- blog-start --> */}
<div className="blog-wrapper-one">
  <div className="container">
    <div className="row">
      <div className="col-lg-9 col-md-12 col-sm-12 col-12">
        <div className="blog-section">
          <div className="blog-img-section">
            <div className="blog-img">
              <img src="images/blog.png" alt="image2" />
            </div>
            <div className="blog-img-content">
              <div className="blog-read">
                <span>28 April 2023</span>
                <h4><Link to="/blog-detail">Beautiful Red Nail Polishes for Your Wedding Day.</Link>
                </h4>
                <Link to="/blog-detail" className="rm-btn">Read More</Link>
              </div>
            </div>
          </div>
          <div className="blog-img-section">
            <div className="blog-img">
              <img src="images/blog-two.png" alt="image2" />
            </div>
            <div className="blog-img-content">
              <div className="blog-read">
                <span>28 April 2023</span>
                <h4><Link to="/blog-detail">Local hair salon owner 'thrilled to be back'
                    after</Link></h4>
                <Link to="/blog-detail" className="rm-btn">Read More</Link>
              </div>
            </div>
          </div>
          <div className="blog-img-section">
            <div className="blog-img">
              <img src="images/blog-three.png" alt="image2" />
            </div>
            <div className="blog-img-content">
              <div className="blog-read">
                <span>28 April 2023</span>
                <h4><Link to="/blog-detail">Ultra Glam Gold Nail Polishes for Holiday.</Link></h4>
                <Link to="/blog-detail" className="rm-btn">Read More</Link>
              </div>
            </div>
          </div>
          <div className="blog-img-section">
            <div className="blog-img">
              <img src="images/blog-five.png" alt="image2" />
            </div>
            <div className="blog-img-content">
              <div className="blog-read">
                <span>28 April 2023</span>
                <h4><Link to="/blog-detail">Hair salon meets clothing boutique at new </Link></h4>
                <Link to="/blog-detail" className="rm-btn">Read More</Link>
              </div>
            </div>
          </div>
          <div className="blog-img-section">
            <div className="blog-img">
              <img src="images/blog-four.png" alt="image2" />
            </div>
            <div className="blog-img-content">
              <div className="blog-read">
                <span>28 April 2023</span>
                <h4><Link to="/blog-detail">Hair salon owner reflects over past months</Link></h4>
                <Link to="/blog-detail" className="rm-btn">Read More</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-pagination-2">
          <nav aria-label="Page navigation example" className="page-navigation">
            <ul className="pagination justify-content-center ">
              <li className="page-item">
                <Link className="page-link" to="#" tabIndex={-1} aria-disabled="true"><i className="fas fa-angle-left" /></Link>
              </li>
              <li className="page-item"><Link className="page-link" to="#">1</Link></li>
              <li className="page-item"><Link className="page-link" to="#">2</Link></li>
              <li className="page-item"><Link className="page-link" to="#">3</Link></li>
              <li className="page-item">
                <Link className="page-link" to="#"><i className="fas fa-angle-right" /></Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <div className="bb-sidebar-wrapper">
          <div className="sidebar-head">
            <h6>Search Keywords</h6>
          </div>
          <div className="sidebar-body">
            <div className="search-blog">
              <input type="search" placeholder="Search" />
              <Link to="#"><i className="fas fa-search" /></Link>
            </div>
          </div>
          <div className="blog-category">
            <div className="sidebar-head">
              <h6>Categories</h6>
            </div>
            <div className="sidebar-body">
              <div className="category-menu">
                <ul>
                  <li><Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Care Essentials</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Interesting to know</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Manicure</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Media</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Nail Repair</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Predicure</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Style</Link>
                  </li>
                  <li>
                    <Link to="/blog"><i className="fas fa-long-arrow-alt-right" />
                      Trends</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="about-blog">
            <div className="sidebar-head">
              <h6>About Us</h6>
            </div>
            <div className="sidebar-body">
              <div className="bb-about-img">
                <img src="images/blog-about.png" alt="img" />
              </div>
              <p>Fusce non eros id tortor eleifend volutpat eu
                et dolor. Quisque ullamcorper risus dui, in
                venenatis mi tempor sed.</p>
              <Link to="/about" className="about-link mt-2 d-block">Read More</Link>
            </div>
          </div>
          <div className="blog-category">
            <div className="sidebar-head">
              <h6>Archives</h6>
            </div>
            <div className="sidebar-body">
              <div className="category-menu">
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      December 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      January 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      February 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      March 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      April 2023</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="insta-post">
            <div className="sidebar-head">
              <h6>Instagram Post</h6>
            </div>
            <div className="sidebar-body">
              <div className="insta-img">
                <ul>
                  <li>
                    <Link to="#"><img src="images/insta-blog1.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"> <img src="images/insta-blog4.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><img src="images/insta-blog2.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"> <img src="images/insta-blog5.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><img src="images/insta-blog3.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><img src="images/insta-blog6.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="recent-blog">
            <div className="sidebar-head">
              <h6>Recent Posts</h6>
            </div>
            <div className="sidebar-body">
              <div className="recent-wrapper">
                <div className="recent-img">
                  <img src="images/blog-recent.png" alt="img" />
                </div>
                <div className="recent-content">
                  <h6>
                    <Link to="/blog-detail">Trend: Light Nail Polishes</Link>
                  </h6>
                  <span>28 April 2023</span>
                </div>
              </div>
              <div className="recent-wrapper recent-gap">
                <div className="recent-img">
                  <img src="images/blog-recent2.png" alt="img" />
                </div>
                <div className="recent-content">
                  <h6>
                    <Link to="/blog-detail">Your Best Manicure Tips</Link>
                  </h6>
                  <span>28 April 2023</span>
                </div>
              </div>
              <div className="recent-wrapper recent-gap border-bottom-0">
                <div className="recent-img">
                  <img src="images/blog-recent3.png" alt="img" />
                </div>
                <div className="recent-content">
                  <h6>
                    <Link to="/blog-detail">
                      Trend: Dark Nail Polishes
                    </Link>
                  </h6>
                  <span>28 April 2023</span>
                </div>
              </div>
            </div>
          </div>
          <div className="tag-blog">
            <div className="sidebar-head">
              <h6>Tags</h6>
            </div>
            <div className="sidebar-body">
              <div className="tag-content">
                <ul>
                  <li><Link to="#">Nails</Link></li>
                  <li><Link to="#">Design</Link></li>
                  <li><Link to="#">Pedicure</Link></li>
                  <li><Link to="#">Care</Link></li>
                  <li><Link to="#">Polishes</Link></li>
                  <li><Link to="#">Tips</Link></li>
                  <li><Link to="#">Advice</Link></li>
                  <li><Link to="#">Paraffin</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




{/* <!-- blog-end --> */}

{/* <!-- news-section-start --> */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <Link to="#" className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- news-section-end --> */}



<Footer />
    </div>
  )
}

export default Blog