import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Service from "./Pages/Service/Service";
import Servicemani from "./Pages/Service/Service-mani";
import Servicepadi from "./Pages/Service/Service-padi";
import Servicenailart from "./Pages/Service/Service-nailart";
import Serviceparahand from "./Pages/Service/Service-parahand";
import Ourteam from "./Pages/Our-team/Our-team";
import Teamsingle from "./Pages/Our-team/Team-single";
import Gallery from "./Pages/Gallery/Gallery";
import Gallery2 from "./Pages/Gallery/Gallery2";
import Gallery3 from "./Pages/Gallery/Gallery3";
import Gallery4 from "./Pages/Gallery/Gallery4";
import Blog from "./Pages/Blog/Blog";
import Blogdetail from "./Pages/Blog/Blog-detail";
import Blogrightsidebar from "./Pages/Blog//Blog-rightsidebar";
import Appointment from "./Pages/Appointment/Appointment";
import Client from "./Pages/Shortcode/Client";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-mani" element={<Servicemani />} />
        <Route path="/service-padi" element={<Servicepadi />} />
        <Route path="/service-nailart" element={<Servicenailart />} />
        <Route path="/service-parahand" element={<Serviceparahand />} />
        <Route path="/our-team" element={<Ourteam />} />
        <Route path="/team-single" element={<Teamsingle />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery2" element={<Gallery2 />} />
        <Route path="/gallery3" element={<Gallery3 />} />
        <Route path="/gallery4" element={<Gallery4 />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail" element={<Blogdetail />} />
        <Route path="/blog-rightsidebar" element={<Blogrightsidebar />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/client" element={<Client />} />
      </Routes>
    </div>
  );
}

export default App;
