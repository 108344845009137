import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
<div className="footer-wrapper float-left">
  <div className="container">
    <div className="row footer-main  animated wow fade-up">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div>
          <div className="footer-logo">
            <img src="images/footer-logo.png" style={{aspectRatio: '1 / 0.4', objectFit: 'contain', height: 141}} alt="img" />
          </div>
          <div className="footer-pera">
            <p>Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since.</p>
          </div>
          <div className="footer-icon">
            <ul>
                
              <li><Link to="#">
                  <i className="fab fa-facebook-f" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-twitter" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-instagram" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-pinterest-p" />
                </Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-12">
        <div className="footer-link-wrapper">
          <h5>Useful links</h5>
          <ul>
            <li><Link to="/">
                <span><i className="fas fa-chevron-right" /></span> Home
              </Link></li>
            <li><Link to="/about">
                <span><i className="fas fa-chevron-right" /></span> About Us
              </Link></li>
            <li><Link to="/our-team">
                <span><i className="fas fa-chevron-right" /></span> Our Team
              </Link></li>
            <li><Link to="/appointment">
                <span><i className="fas fa-chevron-right" /></span> Booking
              </Link></li>
            <li><Link to="/contact">
                <span><i className="fas fa-chevron-right" /></span> Contact Us
              </Link></li>
          </ul>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="footer-insta">
          <h5>Instagram post</h5>
          <ul>
            <li>
              <Link to="#"><img src="images/insta-blog1.png" alt="img" />
                <span><i className="fab fa-instagram" /></span>
              </Link>
            </li>
            <li className="insta-post-gap">
              <Link to="#"> <img src="images/insta-blog4.png" alt="img" />
                <span><i className="fab fa-instagram" /></span>
              </Link>
            </li>
            <li>
              <Link to="#"><img src="images/insta-blog2.png" alt="img" />
                <span><i className="fab fa-instagram" /></span>
              </Link>
            </li>
            <li className="insta-post-gap">
              <Link to="#"> <img src="images/insta-blog5.png" alt="img" />
                <span><i className="fab fa-instagram" /></span>
              </Link>
            </li>
            <li>
              <Link to="#"><img src="images/insta-blog3.png" alt="img" />
                <span><i className="fab fa-instagram" /></span>
              </Link>
            </li>
            <li className="insta-post-gap">
              <Link to="#"><img src="images/insta-blog6.png" alt="img" />
                <span><i className="fab fa-instagram" /></span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="footer-contact">
          <h5>Contact Info</h5>
          <ul>
            <li>
              <div className="address-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="address-content">
                <Link to="#">
                  <span>Address</span>
                  121 Waldeck Street, NY, USA
                </Link>
              </div>
            </li>
            <li>
              <div className="address-icon">
                <i className="fas fa-phone" />
              </div>
              <div className="address-content">
                <Link to="#">
                  <span>PHONE</span>
                  0800-123456, 0800-123489
                </Link>
              </div>
            </li>
            <li>
              <div className="address-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="address-content">
                <Link to="#">
                  <span>EMAIL</span>
                  nailsalon@example.com
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="bottom-footer">
      <p>Copyright © 2023 Nail Salon Template Designed By <Link to="#">Webstrot.</Link></p>
    </div>
  </div>
</div>

  )
}

export default Footer