import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Teamsingle = () => {
  return (
    <div>
        <Header />


        
    {/* <!-- banner-section-start --> */}
<div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Team Single</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Team Single</li>
      </ol>
    </nav>
  </div>
</div>


{/* <!-- banner-section-end --> */}

{/* <!-- blog-start --> */}

<div className="blog-wrapper-one">
  <div className="container">
    <div className="row">
      <div className="col-lg-9 col-md-12 col-sm-12 col-12">
        <div className="blog-detail-section">
          <div className="detail-img">
            <img src="images/team-hair.jpg" alt="img" />
          </div>
          <div className="detail-content">
            <h4>Albert Rose <small>(Hair Expert)</small></h4>
            <div className="detail-pera">
              <p>It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. The point of using Lorem Ipsum is that
                it has a more-or-less normal distribution of letters, as opposed to using 'Content
                here, content here. when an unknown printer took a galley of type and scrambled it
                to make a type specimen book.</p>
              <p className="pera-two">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy
                text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the c1500s. When an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining essentially
                unchanged.</p>
            </div>
            <div className="thought-wrapper">
              <div className="thought-content">
                <img src="images/quote-Icon.png" alt="img" />
                <p>" The Education of Tomorrow, Rooted in Tradition Invite You
                  Learning Management System"</p>
                <span>- by Albert Rose</span>
              </div>
            </div>
            <div className="detail-tag">
              <div className="tag-menu">
                <i className="fas fa-tag" /> Tags:
                <ul>
                  <li>Nails</li>
                  <li>Design</li>
                  <li>Pedicure</li>
                  <li>Tips</li>
                </ul>
              </div>
              <div className="tag-social">
                <ul>
                  <li><Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link></li>
                  <li><Link to="#">
                      <i className="fab fa-twitter" />
                    </Link></li>
                  <li><Link to="#">
                      <i className="fab fa-instagram" />
                    </Link></li>
                  <li><Link to="#">
                      <i className="fab fa-pinterest-p" />
                    </Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <div className="bb-sidebar-wrapper">
          <div className="sidebar-head">
            <h6>Search Keywords</h6>
          </div>
          <div className="sidebar-body">
            <div className="search-blog">
              <input type="search" placeholder="Search" />
              <Link to="#"><i className="fas fa-search" /></Link>
            </div>
          </div>
          <div className="blog-category">
            <div className="sidebar-head">
              <h6>Categories</h6>
            </div>
            <div className="sidebar-body">
              <div className="category-menu">
                <ul>
                  <li><Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Care Essentials</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Interesting to know</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Manicure</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Media</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Nail Repair</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Predicure</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Style</Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      Trends</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="about-blog">
            <div className="sidebar-head">
              <h6>About Us</h6>
            </div>
            <div className="sidebar-body">
              <div className="bb-about-img">
                <img src="images/blog-about.png" alt="img" />
              </div>
              <p>Fusce non eros id tortor eleifend volutpat eu
                et dolor. Quisque ullamcorper risus dui, in
                venenatis mi tempor sed.</p>
              <Link to="/about" className="about-link mt-2 d-block">Read More</Link>
            </div>
          </div>
          <div className="blog-category">
            <div className="sidebar-head">
              <h6>Archives</h6>
            </div>
            <div className="sidebar-body">
              <div className="category-menu">
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      December 2022
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      January 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      February 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-long-arrow-alt-right" />
                      March 2023
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><i className="fas fa-long-arrow-alt-right" />
                      April 2022</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="insta-post">
            <div className="sidebar-head">
              <h6>Instagram Post</h6>
            </div>
            <div className="sidebar-body">
              <div className="insta-img">
                <ul>
                  <li>
                    <Link to="#"><img src="images/insta-blog1.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"> <img src="images/insta-blog4.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><img src="images/insta-blog2.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"> <img src="images/insta-blog5.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><img src="images/insta-blog3.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#"><img src="images/insta-blog6.png" alt="img" />
                      <span><i className="fab fa-instagram" /></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="recent-blog">
            <div className="sidebar-head">
              <h6>Recent Posts</h6>
            </div>
            <div className="sidebar-body">
              <div className="recent-wrapper">
                <div className="recent-img">
                  <img src="images/blog-recent.png" alt="img" />
                </div>
                <div className="recent-content">
                  <h6>
                    <Link to="#">Trend: Light Nail Polishes</Link>
                  </h6>
                  <span>28 April 2023</span>
                </div>
              </div>
              <div className="recent-wrapper recent-gap">
                <div className="recent-img">
                  <img src="images/blog-recent2.png" alt="img" />
                </div>
                <div className="recent-content">
                  <h6>
                    <Link to="#">Your Best Manicure Tips</Link>
                  </h6>
                  <span>18 April 2023</span>
                </div>
              </div>
              <div className="recent-wrapper recent-gap border-bottom-0">
                <div className="recent-img">
                  <img src="images/blog-recent3.png" alt="img" />
                </div>
                <div className="recent-content">
                  <h6>
                    <Link to="#">
                      Trend: Dark Nail Polishes
                    </Link>
                  </h6>
                  <span>8 April 2023</span>
                </div>
              </div>
            </div>
          </div>
          <div className="tag-blog">
            <div className="sidebar-head">
              <h6>Tags</h6>
            </div>
            <div className="sidebar-body">
              <div className="tag-content">
                <ul>
                  <li><Link to="#">Nails</Link></li>
                  <li><Link to="#">Design</Link></li>
                  <li><Link to="#">Pedicure</Link></li>
                  <li><Link to="#">Care</Link></li>
                  <li><Link to="#">Polishes</Link></li>
                  <li><Link to="#">Tips</Link></li>
                  <li><Link to="#">Advice</Link></li>
                  <li><Link to="#">Paraffin</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



{/* <!-- blog-end --> */}

{/* <!-- news-section-start --> */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <div className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- news-section-end --> */}
        <Footer />
    </div>
  )
}

export default Teamsingle