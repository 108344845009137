import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div>
      <Header />

      {/* banner-section-start  */}
      <div class="inner-page-wrapper float-left">
        <div class="inner-head  animated wow fade-up">
          <h1>Contact Us</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb justify-content-center">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* banner-section-end  */}

      {/* contact-section-start  */}

      <div className="contact-wrapper">
        <div className="container">
          <div className="row animated wow fade-up">
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="contact-box">
                <div className="contact-icon">
                  <i className="fas fa-map-marker-alt" />
                </div>
                <div className="contact-content">
                  <h3>Address</h3>
                  <Link to="#">
                    121 Waldeck Street, NewYork Code: #010101
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="contact-box">
                <div className="contact-icon">
                  <i className="fas fa-phone" />
                </div>
                <div className="contact-content">
                  <h3>Phone</h3>
                  <Link to="#">
                    + 0800-123456
                    <br />+ 0800-123489
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="contact-box">
                <div className="contact-icon">
                  <i className="fas fa-envelope" />
                </div>
                <div className="contact-content">
                  <h3>Email</h3>
                  <Link to="mailto:nailsalon01@example.com">
                    nailsalon01@example.com nailsalon02@example.com
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="contact-box">
                <div className="contact-icon">
                  <i className="fas fa-globe-asia" />
                </div>
                <div className="contact-content">
                  <h3>Website</h3>
                  <Link to="mailto:www.nailsalon01.com">
                    www.nailsalon01.com www.nailsalon01.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     <div className="contact-map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.644307465103!2d76.04800561444114!3d22.963323124303425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963179aa37da85d%3A0x9ad74f985a500d01!2sWebstrot%20Technology!5e0!3m2!1sen!2sin!4v1637652142209!5m2!1sen!2sin" title="changeyourlook" allowFullScreen loading="lazy" />
</div>


      <div className="contact-form">
        <div className="container">
          <div className="row">
            <div className="sidebar-head">
              <h6>Have a Question?</h6>
            </div>
            <div className="sidebar-body form-head">
              <form className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="contact-input">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                    <i className="fas fa-user" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="contact-input">
                    <input
                      type="email"
                      className="form-control"
                      id="contactemail"
                      placeholder="Email"
                      required
                    />
                    <i className="fas fa-envelope" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contact-input">
                    <textarea
                      placeholder="Your Text"
                      required
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contact-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="check"
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="check"
                      required
                    >
                      I agree that my submitted data is being collected and
                      stored.
                    </label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="tb_es_btn_div">
                    <div className="response center" />
                    <input
                      type="hidden"
                      name="form_type"
                      defaultValue="contact"
                    />
                    <div className="tb_es_btn_wrapper os_contact_input">
                      <div className="contact-btn">
                        <button
                          type="submit"
                          className="submitForm main-btn-red"
                        >
                          <em>
                            <i />
                            <i />
                            <i />
                            <i />
                            <i />
                            <i />
                            <i />
                            <i />
                            <i />
                            <i />
                          </em>
                          <span>Send Message</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* contact-section-end  */}

      {/* news-section-start  */}

      <div className="news-wrapper float-left">
        <div className="container">
          <div className="row  animated wow fade-up">
            <div className="news-head">
              <h5>Newsletter</h5>
              <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
            </div>
            <div className="subscribe-form">
              <input type="email" placeholder="Enter Your Email" />
              <div className="suscribe-btn">
                <Link to="#" className="main-btn-red">
                  <em>
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                  </em>
                  <span>Subscribe Now</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* news-section-end  */}
      <Footer />
    </div>
  );
};

export default Contact;
