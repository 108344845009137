import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [togglemenu, settogglemenu] = useState(false);
  const [openinput, setopeninput] = useState(false);
  const [openmenu, setopenmenu] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
  });

  const toggleOption = (option) => {
    setopenmenu((prevMenu) => ({
      ...prevMenu,
      [option]: !prevMenu[option],
    }));
  };
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <div className="main-header-wrapper float-left">
        <div className="main-menu float-left">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                <Link className="navbar-brand" to="/">
                  <img src="images/logo.png" alt="logo" />
                </Link>
              </div>
              <div className="col-lg-10 col-md-6 d-xl-block d-lg-block d-md-none d-sm-none d-none">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mb-2 mb-lg-0">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          Home
                          {/* <i className="fas fa-chevron-right" /> */}
                        </Link>
                        {/* <ul className="sub-menu">
                          <li>
                            <Link to="/">Home 1</Link>
                          </li>
                          <li>
                            <Link to="index-two.html">Home 2</Link>
                          </li>
                          <li>
                            <Link to="index-three.html">Home 3</Link>
                          </li>
                        </ul> */}
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="#">
                          Page
                          <i className="fas fa-chevron-right" />
                        </Link>

                        <ul className="sub-menu">
                          <li>
                            <Link to="/about">About Us</Link>
                          </li>
                          <li>
                            <Link to="/our-team">Team</Link>
                          </li>
                          <li>
                            <Link to="/team-single">Expert</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="#">
                          Services
                          <i className="fas fa-chevron-right" />
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/service">Service page</Link>
                          </li>
                          <li>
                            <Link to="/service-mani"> Manicure Service</Link>
                          </li>
                          <li>
                            <Link to="/service-padi"> Pedicure Service</Link>
                          </li>
                          <li>
                            <Link to="/service-nailart"> Nail Art Service</Link>
                          </li>
                          <li>
                            <Link to="/service-parahand">
                              Paraffin Hand Service{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="#">
                          Gallery
                          <i className="fas fa-chevron-right" />
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/gallery">3 Column</Link>
                          </li>
                          <li>
                            <Link to="/gallery2">4 Column</Link>
                          </li>
                          <li>
                            <Link to="/gallery3">5 Column</Link>
                          </li>
                          <li>
                            <Link to="/gallery4">Masonry Gallery</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="#">
                          Blog
                          <i className="fas fa-chevron-right" />
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/blog">Blog Left Sidebar</Link>
                          </li>
                          <li>
                            <Link to="/blog-detail">Blog Details</Link>
                          </li>
                          <li>
                            <Link to="/blog-rightsidebar">
                              Blog Right Sidebar
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="nav-item">
                        <Link className="nav-link" to="#">
                          Shortcode
                          <i className="fas fa-chevron-right" />
                        </Link>
                        <div className="sub-menu mega-menu">
                          <ul>
                            <li>
                              <Link to="accordion.html">Accordion</Link>
                            </li>
                            <li>
                              <Link to="alert.html">Alert</Link>
                            </li>
                            <li>
                              <Link to="button.html">Button</Link>
                            </li>
                            <li>
                              <Link to="/client">Client</Link>
                            </li>
                            <li>
                              <Link to="counter.html">Counter</Link>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <Link to="form.html">Form</Link>
                            </li>
                            <li>
                              <Link to="icon.html">Icon</Link>
                            </li>
                            <li>
                              <Link to="list.html">List</Link>
                            </li>
                            <li>
                              <Link to="pricing.html">Pricing Table</Link>
                            </li>
                            <li>
                              <Link to="social-icon.html">Social Icon</Link>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <Link to="tab.html">Tab</Link>
                            </li>
                            <li>
                              <Link to="team.html">Team</Link>
                            </li>
                            <li>
                              <Link to="testimonial.html">Testimonial</Link>
                            </li>
                            <li>
                              <Link to="gallery-short.html">Gallery</Link>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact">
                          Contact Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <div className="search">
                          <div className="search-box">
                            <input
                              type="text"
                              className={`search-input ${
                                openinput ? "main" : ""
                              }`}
                              placeholder="Search Here"
                            />
                            <div
                              className="search-btn"
                              onClick={() => {
                                setopeninput(!openinput);
                              }}
                            >
                              <i className="fas fa-search" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="nav-link">
                          <div className="main-btn">
                            <span
                              style={{
                                display: "flex",
                                gap: 4,
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                              >
                                Login
                              </span>
                              /
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop1"
                                style={{ cursor: "pointer" }}
                              >
                                Register
                              </span>
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="col-md-6 col-sm-6 col-6 d-xl-none d-lg-none">
                <button
                  className="nav-toggle"
                  type="button"
                  data-bs-toggle="collapse"
                  onClick={() => {
                    settogglemenu(!togglemenu);
                  }}
                  data-bs-target="#sidebar"
                  aria-controls="sidebar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="nav-toggle__text">Toggle Menu</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="mobile-menu-wrapper">
        <div
          id="sidebar"
          className={togglemenu === false ? "closemenu" : "openmenu"}
          style={{ overflow: togglemenu === true ? "scroll" : "hidden" }}
        >
          <div className="logo">
            <img src="images/logo.png" alt="logo" />
          </div>
          <div
            id="toggle-close"
            onClick={() => {
              settogglemenu(!togglemenu);
            }}
          >
            ×
          </div>
          <div id="cssmenu">
            <input type="search" placeholder="Search Here...." />
            <ul className="float-left">
              <li>
                <Link to="/">Home</Link>
                {/* <ul
                  style={{
                    display: openmenu.option1 === false ? "none" : "block",
                  }}
                >
                  <li>
                    <Link to="/">Home 1</Link>
                  </li>
                  <li>
                    <Link to="index-two.html">Home 2</Link>
                  </li>
                  <li>
                    <Link to="index-three.html">Home 3</Link>
                  </li>
                </ul> */}
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li
                className={
                  openmenu.option2 === false ? "has-sub" : "has-sub open"
                }
                onClick={() => {
                  toggleOption("option2");
                }}
              >
                <Link to="#">Services</Link>
                <ul
                  style={{
                    display: openmenu.option2 === false ? "none" : "block",
                  }}
                >
                  <li>
                    <Link to="/service">Service page</Link>
                  </li>
                  <li>
                    <Link to="/service-mani"> Manicure Service</Link>
                  </li>
                  <li>
                    <Link to="/service-padi"> Pedicure Service</Link>
                  </li>
                  <li>
                    <Link to="/service-nailart"> Nail Art Service</Link>
                  </li>
                  <li>
                    <Link to="/service-parahand">Paraffin Hand Service </Link>
                  </li>
                </ul>
              </li>
              <li
                className={
                  openmenu.option3 === false ? "has-sub" : "has-sub open"
                }
                onClick={() => {
                  toggleOption("option3");
                }}
              >
                <Link to="#">Gallery</Link>
                <ul
                  style={{
                    display: openmenu.option3 === false ? "none" : "block",
                  }}
                >
                  <li>
                    <Link to="/gallery">3 Column</Link>
                  </li>
                  <li>
                    <Link to="/gallery2">4 Column</Link>
                  </li>
                  <li>
                    <Link to="/gallery3">5 Column</Link>
                  </li>
                  <li>
                    <Link to="/gallery4">Masonry Gallery</Link>
                  </li>
                </ul>
              </li>
              <li
                className={
                  openmenu.option4 === false ? "has-sub" : "has-sub open"
                }
                onClick={() => {
                  toggleOption("option4");
                }}
              >
                <Link to="#">Blog</Link>
                <ul
                  style={{
                    display: openmenu.option4 === false ? "none" : "block",
                  }}
                >
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/blog-detail">Blog Details</Link>
                  </li>
                  <li>
                    <Link to="/blog-rightsidebar">Blog Right Sidebar</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/our-team">Team</Link>
              </li>
              <li>
                <Link to="/team-single">Expert</Link>
              </li>
              {/* <li
                className={
                  openmenu.option5 === false ? "has-sub" : "has-sub open"
                }
                onClick={() => {
                  toggleOption("option5");
                }}
              >
                <Link to="#">Shortcode</Link>
                <ul
                  style={{
                    display: openmenu.option5 === false ? "none" : "block",
                  }}
                >
                  <li>
                    <Link to="accordion.html"> Accordion</Link>
                  </li>
                  <li>
                    <Link to="alert.html"> Alert</Link>
                  </li>
                  <li>
                    <Link to="button.html">Button</Link>
                  </li>
                  <li>
                    <Link to="/client">Client</Link>
                  </li>
                  <li>
                    <Link to="counter.html">Counter</Link>
                  </li>
                  <li>
                    <Link to="form.html"> Form</Link>
                  </li>
                  <li>
                    <Link to="icon.html"> Icon</Link>
                  </li>
                  <li>
                    <Link to="list.html">List</Link>
                  </li>
                  <li>
                    <Link to="pricing.html">Pricing Table</Link>
                  </li>
                  <li>
                    <Link to="social-icon.html">Social Icon</Link>
                  </li>
                  <li>
                    <Link to="tab.html">Tab</Link>
                  </li>
                  <li>
                    <Link to="team.html">Team</Link>
                  </li>
                  <li>
                    <Link to="testimonial.html">Testimonial</Link>
                  </li>
                  <li>
                    <Link to="gallery-short.html">Gallery</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
            <div className="sidebar-social float-left">
              <ul className="float-left">
                <li>
                  <Link to="#">
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fab fa-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fab fa-instagram" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fab fa-pinterest-p" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Login Modal */}
      <div className="login-wrapper">
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close login-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-header">
                <h3 className="modal-title" id="staticBackdropLabel1">
                  <span>Login</span>
                </h3>
              </div>
              <div className="modal-body">
                <div className="register-body">
                  <form>
                    <div>
                      <input type="text" placeholder="User Name*" />
                    </div>
                    <div>
                      <input type="password" placeholder="Password*" />
                    </div>
                    <div>
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck2"
                          >
                            Remember Me
                          </label>
                        </li>
                        <li>
                          <Link href="#">Forgot Password?</Link>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <div className="sign-btn">
                  <Link href="#" className="main-btn-red">
                    <em>
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                    </em>
                    <span>Log In</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Register Modal  */}
      <div className="login-wrapper">
        <div
          className="modal fade"
          id="staticBackdrop1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close login-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-header">
                <h3 className="modal-title" id="staticBackdropLabel">
                  <span>Register</span>
                </h3>
              </div>
              <div className="modal-body">
                <div className="register-body">
                  <form>
                    <div>
                      <input type="text" placeholder="User Name*" />
                    </div>
                    <div>
                      <input type="email" placeholder="Email*" />
                    </div>
                    <div>
                      <input type="password" placeholder="Password*" />
                    </div>
                    <div>
                      <input type="password" placeholder="Confirm Password*" />
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Yes, I understand and agree
                        <Link href="#">Terms &amp; Conditions.</Link>{" "}
                      </label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <div className="sign-btn">
                  <Link href="#" className="main-btn-red">
                    <em>
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                      <i />
                    </em>
                    <span>Sign Up</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
