import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Client = () => {
  return (
    <div>
        <Header />
        {/* <!-- banner section start start--> */}
<div>
  <div className="inner-page-wrapper float-left">
    <div className="inner-head">
      <h1>Client</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Client</li>
        </ol>
      </nav>
    </div>
  </div>
  {/* First Client start */}
  <div className="client1-wrapper padd-100 float-start w-100">
    <div className="section-heading animated wow fade-up">
      <h4>Client Style 01</h4>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 pd-2">
          <Link to="#"><img src="images/partner/logo-cup.png" alt="img" />
          </Link>
        </div>
      </div>
    </div>
  </div>
  {/* First Client End*/}
  {/*Second  Client Start*/}
  <div className="client2-wrapper padd-100 float-start w-100">
    <div className="container">
      <div className="section-heading animated wow fade-up">
        <h4>Client Style 02</h4>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <Link to="#">
            <img src="images/partner/client-logo31.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <Link to="#">
            <img src="images/partner/client-logo32.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <Link to="#">
            <img src="images/partner/client-logo33.png" alt="img" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <Link to="#">
            <img src="images/partner/client-logo34.png" alt="img" />
          </Link>
        </div>
      </div>
    </div>
  </div>
  {/* Second Client Start*/}
  {/*Third Client Start*/}
  <div className="client3-wrapper padd-100 float-start w-100">
    <div className="container">
      <div className="section-heading animated wow fade-up">
        <h4>Client Style 03</h4>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="hover-box animated wow fade-box">
            <Link to="#">
              <img src="images/partner/clients-logo41.png" alt="img" />
            </Link>
            <span className="overlay" />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="hover-box animated wow fade-box">
            <Link to="#">
              <img src="images/partner/clients-logo42.png" alt="img" />
            </Link>
            <span className="overlay" />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="hover-box animated wow fade-box">
            <Link to="#">
              <img src="images/partner/clients-logo43.png" alt="img" />
            </Link>
            <span className="overlay" />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="hover-box animated wow fade-box">
            <Link to="#">
              <img src="images/partner/clients-logo44.png" alt="img" />
            </Link>
            <span className="overlay" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    {/* <!-- Third Client Start--> */}
        <Footer />
    </div>
  )
}

export default Client