import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Appointment = () => {
  return (
    <div>
    <Header />

    
    {/* <!-- banner-section-start --> */}
<div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Appointment</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Appointment</li>
      </ol>
    </nav>
  </div>
</div>


    {/* <!-- banner-section-end --> */}

    {/* <!-- blog-start --> */}
<div className="contact-form appointment-wrap">
  <div className="container">
    <div className="row">
      <div className="sidebar-head">
        <h6>Book An Appointment</h6>
      </div>
      <div className="sidebar-body form-head">
        <form className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-input">
              <input type="text" className="form-control" placeholder="Name" required />
              <i className="fas fa-user" />
            </div>
            <div className="contact-input">
              <input type="tel" className="form-control" placeholder="Phone" />
              <i className="fas fa-phone" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="contact-input">
              <input type="email" className="form-control" id="contactemail" placeholder="Email" required />
              <i className="fas fa-envelope" />
            </div>
            <div className="input-form">
              <div className="contact-input">
                <input className="form-control" id="date" name="date" placeholder="MM/DD/YYYY" type="text" />
                <span className="form-date"><i className="fas fa-calendar-minus" /></span>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="contact-input">
              <textarea placeholder="Your Text" defaultValue={""} />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="contact-check">
              <input type="checkbox" className="form-check-input" id="check" />
              <label className="form-check-label" htmlFor="check">I agree that my submitted data is being
                collected and stored.</label>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="tb_es_btn_div">
              <div className="response center" />
              <input type="hidden" name="form_type" defaultValue="contact" />
              <div className="tb_es_btn_wrapper os_contact_input">
                <div className="contact-btn appointment-btn">
                  <button type="submit" className="main-btn-red">
                    <em><i /><i /><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Submit
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


    {/* <!-- blog-end --> */}

    {/* <!-- news-section-start --> */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <Link to="#" className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>


    {/* <!-- news-section-end --> */}
    <Footer />
    </div>
  )
}

export default Appointment