import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Ourteam = () => {
  return (
    <div>
        <Header />
        {/* <!-- banner section start start--> */}
   <div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Expert </h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Expert </li>
      </ol>
    </nav>
  </div>
</div>


    {/* <!----------- team-section-start ----------> */}
<div className="team-wrapper team-section float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="team-head animated wow fade-up">
          <h5>Our Staff</h5>
          <h3>OUR PROFESSIONALS</h3>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-1.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Jordan Mike</h5>
              </Link>
              <span>Manager</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-2.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>KELLEY MILES</h5>
              </Link>
              <span>Director</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-3.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Smith Dan</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-4.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Carolyn Olson</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-5.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Jordan Mike</h5>
              </Link>
              <span>Manager</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-6.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>KELLEY MILES</h5>
              </Link>
              <span>Director</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-7.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Smith Dan</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-8.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/team-single">
                <h5>Carolyn Olson</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-12">
        <nav aria-label="Page navigation example" className="page-navigation">
          <ul className="pagination justify-content-center ">
            <li className="page-item">
              <Link className="page-link" to="#" tabIndex={-1} aria-disabled="true"><i className="fas fa-angle-left" /></Link>
            </li>
            <li className="page-item"><Link className="page-link" to="#">1</Link></li>
            <li className="page-item"><Link className="page-link" to="#">2</Link></li>
            <li className="page-item"><Link className="page-link" to="#">3</Link></li>
            <li className="page-item">
              <Link className="page-link" to="#"><i className="fas fa-angle-right" /></Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>


    {/* <!----------- team-section-end ------------> */}

        <Footer />
    </div>
  )
}

export default Ourteam