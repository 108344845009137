import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Servicepadi = () => {
  return (
    <div>
        <Header />



        
    {/* <!-- banner-section-start --> */}
<div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Padicure Services</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item"><Link to="#">Services</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Padicure Services</li>
      </ol>
    </nav>
  </div>
</div>


{/* <!-- banner-section-end --> */}

{/* <!-- service-detail-start --> */}
<div className="service-detail-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-9 col-md-12 col-sm-12 col-12">
        <div className="service-detail">
          <div className="service-slider">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="service-img">
                  <img src="images/pedicure-4.jpg" alt="img" />
                </div>
              </div>
              <div className="item">
                <div className="service-img">
                  <img src="images/pedicure-1.jpg" alt="img" />
                </div>
              </div>
              <div className="item">
                <div className="service-img">
                  <img src="images/pedicure-2.jpg" alt="img" />
                </div>
              </div>
              <div className="item">
                <div className="service-img">
                  <img src="images/pedicure-3.jpg" alt="img" />
                </div>
              </div>
            </div>
          </div>
          <div className="service-detail-content">
            <h3>PADICURE TREATMENTS</h3>
            <p>Pedicure is a service aimed at improving the appearance
              of the feet and toenails. Pedicure has many health
              benefits, including prevention of nail diseases and
              disorders, cosmetic and therapeutic benefits.
            </p>
            <p className="service-pera">
              Pedicure involves working on toenails
              and removing dead skin cells at the sole of
              the feet using a rough stone called ‘pumice
              stone’ and other implements. These days,
              leg care below the knee is also included in
              pedicure.
              Leg care includes depilation (hair
              removal) by shaving, waxing or some other
              technique. This is followed by granular
              exfoliation, application of moisturising
              cream and ending the procedure with a leg massage. A
              monthly treatment helps keep the feet and toenails in
              a healthy condition, although excessive hard skin may
              need frequent pedicure sessions.
            </p>
            <p className="service-pera-two">
              Support the ankle with one hand and effleurage
              six times up to the knee with
              each hand separately. Cover the front, sides and
              back of the lower leg.
              This must be followed by circular finger movement
              kneading the knee. Kneading is a form of massage
              used to treat the muscles, and reduce stiffness
              and pain.
              Give palm kneading to the calf. Now, give circular thumb kneading to the front of
              the leg from ankle to knee.
            </p>
          </div>
          <div className="booking-wrapper">
            <div className="booking-head">
              <h6>Please Select Your Booking</h6>
            </div>
            <div className="booking-content">
              <div className="booking-select">
                <i className="fas fa-user" />
                <h6>Select Expert</h6>
              </div>
              <div className="booking-img-wrapper">
                <ul>
                  <li>
                    <div className="span">
                      <img src="images/team-1.png" alt="img" />
                      <p>Jordan Mike</p>
                    </div>
                  </li>
                  <li>
                    <div className="span">
                      <img src="images/team-2.png" alt="img" />
                      <p>Kelley Miles</p>
                    </div>
                  </li>
                  <li>
                    <div className="span">
                      <img src="images/team-3.png" alt="img" />
                      <p>Smith Dan</p>
                    </div>
                  </li>
                  <li>
                    <div className="span">
                      <img src="images/team-4.png" alt="img" />
                      <p>Carolyn Olson</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="booking-form">
                <form className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="input-form">
                      <input type="text" className="form-control" placeholder="Name" required />
                      <i className="fas fa-user" />
                    </div>
                    <div className="input-form">
                      <div className="input-group">
                        <input className="form-control" id="date" name="date" placeholder="MM/DD/YYYY" type="text" required />
                        <span className="form-date"><i className="fas fa-calendar-minus" /></span>
                      </div>
                    </div>
                    <div className="input-form">
                      <div className="input-group">
                        <input className="form-control" id="date1" name="date" placeholder="MM/DD/YYYY" type="text" required />
                        <span className="form-date"><i className="fas fa-calendar-minus" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input-form">
                      <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email" required />
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="input-form">
                      <input type="tel" placeholder="Phone" required />
                      <i className="fas fa-phone" />
                    </div>
                    <div className="input-form">
                      <div className="selectBox">
                        <div className="selectBox__value">Select Service</div>
                        <div className="dropdown-menu">
                          <Link to="#" className="dropdown-item active">Service
                            One</Link>
                          <Link to="#" className="dropdown-item">Service Two</Link>
                          <Link to="#" className="dropdown-item">Service Three</Link>
                          <Link to="#" className="dropdown-item">Service Four</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="booking-info">
                <div className="booking-select main-info">
                  <i className="fas fa-info-circle" />
                  <h6>Booking Information</h6>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="booking-total total-border">
                      <ul>
                        <li>Total Guest</li>
                        <li>Service Charge :</li>
                        <li>Tax Paid :</li>
                        <li className="total">Total Amount :</li>
                      </ul>
                      <ul>
                        <li>02</li>
                        <li>$50</li>
                        <li>$3</li>
                        <li className="total total-color">$53</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="booking-total">
                      <ul>
                        <li>Expert:</li>
                        <li>Date:</li>
                        <li>Time:</li>
                        <li>Service:</li>
                      </ul>
                      <ul className="book-response">
                        <li>Isha Doe</li>
                        <li>29 April 2023</li>
                        <li>10.00 EST</li>
                        <li>Massage</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-wrapper">
                <div className="booking-select">
                  <i className="far fa-credit-card" />
                  <h6>Select Payment Method</h6>
                </div>
                <div className="payment">
                  <div className="form-group">
                    <input type="radio" name="one" id="one" />
                    <label htmlFor="one">Paypal</label>
                  </div>
                  <div className="form-group">
                    <input type="radio" name="one" id="two" />
                    <label htmlFor="two">Bank Transfer</label>
                  </div>
                  <div className="form-group">
                    <input type="radio" name="one" id="three" />
                    <label htmlFor="three">Cash Payment</label>
                  </div>
                </div>
                <div className="payment-btn">
                  <button type="submit">
                    <Link to="#" className="submitForm main-btn-red">
                      <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Book
                        Now
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-wrapper">
            <div className="booking-head">
              <h6>Client Reviews</h6>
            </div>
            <div className="booking-content">
              <div className="post-wrapper">
                <div className="booking-select">
                  <i className="fas fa-edit" />
                  <h6>Post Your Review</h6>
                </div>
                <div className="book-star">
                  <ul>
                    <li className="yellow-star"><i className="fas fa-star" /></li>
                    <li className="yellow-star"><i className="fas fa-star" /></li>
                    <li className="yellow-star"><i className="fas fa-star" /></li>
                    <li className="gray-star"><i className="fas fa-star" /></li>
                    <li className="gray-star"><i className="fas fa-star" /></li>
                  </ul>
                </div>
              </div>
              <div className="booking-form">
                <form className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="input-form">
                      <input type="text" className="form-control" placeholder="Name" required />
                      <i className="fas fa-user" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input-form">
                      <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="Email" required />
                      <i className="fas fa-envelope" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="input-form">
                      <textarea placeholder="Your Text" required defaultValue={""} />
                    </div>
                  </div>
                  <div className="post-btn">
                    <button type="submit">
                      <Link to="#" className="submitForm main-btn-red">
                        <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Post
                          Review
                        </span>
                      </Link>
                    </button>
                  </div>
                </form>
              </div>
              <div className="client-post-wrapper">
                <div className="client-post">
                  <div className="post-img">
                    <img src="images/post-1.png" alt="img" />
                  </div>
                  <div className="post-content">
                    <div className="post-name">
                      <div className="client">
                        <h6>Akshay H.</h6>
                        <span>10:45 AM &nbsp; | &nbsp; Feb 06, 2023</span>
                      </div>
                      <div className="client-star">
                        <ul>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <div className="client-pera">
                      <p>It is a long established fact that a reader will be distracted by the
                        readable content of a page when looking at its layout. The point of
                        using Lorem Ipsum.</p>
                    </div>
                  </div>
                </div>
                <div className="client-post">
                  <div className="post-img">
                    <img src="images/post-2.png" alt="img" />
                  </div>
                  <div className="post-content">
                    <div className="post-name">
                      <div className="client">
                        <h6>Liza D'souza</h6>
                        <span>10:45 AM &nbsp; | &nbsp; Feb 04, 2023</span>
                      </div>
                      <div className="client-star">
                        <ul>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <div className="client-pera">
                      <p>It is a long established fact that a reader will be distracted by the
                        readable content of a page when looking at its layout. The point of
                        using Lorem Ipsum.</p>
                    </div>
                  </div>
                </div>
                <div className="client-post">
                  <div className="post-img">
                    <img src="images/post-3.png" alt="img" />
                  </div>
                  <div className="post-content">
                    <div className="post-name">
                      <div className="client">
                        <h6>Ajay S.</h6>
                        <span>10:45 AM &nbsp; | &nbsp; Feb 05, 2023</span>
                      </div>
                      <div className="client-star">
                        <ul>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <div className="client-pera">
                      <p>It is a long established fact that a reader will be distracted by the
                        readable content of a page when looking at its layout. The point of
                        using Lorem Ipsum.</p>
                    </div>
                  </div>
                </div>
                <div className="client-post last-post">
                  <div className="post-img">
                    <img src="images/post-4.png" alt="img" />
                  </div>
                  <div className="post-content">
                    <div className="post-name">
                      <div className="client">
                        <h6>Aditi S.</h6>
                        <span>10:45 AM &nbsp; | &nbsp; Feb 07, 2023</span>
                      </div>
                      <div className="client-star">
                        <ul>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="yellow-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                          <li className="gray-star"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <div className="client-pera">
                      <p>It is a long established fact that a reader will be distracted by the
                        readable content of a page when looking at its layout. The point of
                        using Lorem Ipsum.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <div className="service-right-side">
          <div className="work-head">
            <h6>Working Hours</h6>
          </div>
          <div className="book-time">
            <div className="time-wrapeer">
              <ul className="week-time">
                <li>Monday</li>
                <li>Tuesday</li>
                <li>Wednesday</li>
                <li>Thursday</li>
                <li>Friday</li>
                <li>Saturday</li>
                <li>Sunday</li>
              </ul>
              <ul>
                <li>9 AM - 5 PM</li>
                <li>9 AM - 5 PM</li>
                <li>9 AM - 5 PM</li>
                <li>9 AM - 5 PM</li>
                <li>9 AM - 5 PM</li>
                <li>9 AM - 3 PM</li>
                <li className="close">Closed</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="service-right-side service-gap">
          <div className="work-head">
            <h6>Location</h6>
          </div>
          <div className="location-wrapper">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.644307465103!2d76.04800561444114!3d22.963323124303425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963179aa37da85d%3A0x9ad74f985a500d01!2sWebstrot%20Technology!5e0!3m2!1sen!2sin!4v1636698570494!5m2!1sen!2sin" height={260} style={{border: 0}} title='service-padi' allowFullScreen loading="lazy" />
          </div>
        </div>
        <div className="service-right-side service-gap">
          <div className="work-head">
            <h6>FAQ</h6>
          </div>
          <div className="faq-accordion">
            <div className="accordions" id="secondAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                    Learn Anytime &amp; Anywhere
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#secondAccordion">
                  <div className="accordion-body">
                    <p>It is a long established fact that a reader will be distracted by the
                      readable content of a page when looking at its layout.</p>
                    <ul>
                      <li> <span /> Nisi elit consequat ipsum.</li>
                      <li> <span /> Proin gravida nibh vel.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false">
                    Choose your own best Way
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#secondAccordion">
                  <div className="accordion-body">
                    <p>It is a long established fact that a reader will be distracted by the
                      readable content of a page when looking at its layout.</p>
                    <ul>
                      <li> <span /> Nisi elit consequat ipsum.</li>
                      <li> <span /> Proin gravida nibh vel.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Get Training From Experts
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#secondAccordion">
                  <div className="accordion-body">
                    <p>It is a long established fact that a reader will be distracted by the
                      readable content of a page when looking at its layout.</p>
                    <ul>
                      <li> <span /> Nisi elit consequat ipsum.</li>
                      <li> <span /> Proin gravida nibh vel.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-right-side service-gap">
          <div className="work-head">
            <h6>Social Share</h6>
          </div>
          <div className="service-social">
            <ul>
              <li><Link to="#">
                  <i className="fab fa-facebook-f" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-twitter" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-pinterest-p" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-linkedin-in" />
                </Link></li>
              <li><Link to="#">
                  <i className="fab fa-youtube" />
                </Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* <!-- service-detail-end --> */}

{/* <!-- news-section-start --> */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <Link to="#" className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- news-section-end --> */}
        <Footer />
    </div>
  )
}

export default Servicepadi