import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Service = () => {
  return (
    <div>
        <Header />




        
    {/* <!-- banner-section-start --> */}
<div className="inner-page-wrapper float-left">
  <div className="inner-head">
    <h1>Services</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Services</li>
      </ol>
    </nav>
  </div>
</div>


{/* <!-- banner-section-end --> */}

{/* <!-- price-section-start --> */}
<div className="price-wrapper float-left">
  <div className="container">
    <div className="price-head">
      <h5>Prices Plans</h5>
      <h3>Our Pricing Table</h3>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="price-box-wrapper">
          <div className="price-box-head">
            <h3>MANICURE
              TREATMENTS</h3>
            <p>From <span>$30.99</span> </p>
          </div>
          <div className="price-menu">
            <ul>
              <li><i className="fas fa-check-square" /> Color/Gel Removal</li>
              <li><i className="fas fa-check-square" /> Plain Manicure</li>
              <li><i className="fas fa-check-square" /> Single Colour</li>
              <li><i className="fas fa-check-square" /> Nail Art</li>
            </ul>
          </div>
          <div className="book-btn">
            <Link to="/service-mani">Book Now</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="price-box-wrapper">
          <div className="price-box-head">
            <h3>PediCURE
              TREATMENTS</h3>
            <p>From <span>$30.99</span> </p>
          </div>
          <div className="price-menu">
            <ul>
              <li><i className="fas fa-check-square" /> Color/Gel Removal</li>
              <li><i className="fas fa-check-square" /> Plain Manicure</li>
              <li><i className="fas fa-check-square" /> Single Colour</li>
              <li><i className="fas fa-check-square" /> Nail Art</li>
            </ul>
          </div>
          <div className="book-btn">
            <Link to="/service-padi">Book Now</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="price-box-wrapper">
          <div className="price-box-head price-response">
            <h3> nail arts
              TREATMENTS
            </h3>
            <p>From <span>$30.99</span> </p>
          </div>
          <div className="price-menu">
            <ul>
              <li><i className="fas fa-check-square" /> Color/Gel Removal</li>
              <li><i className="fas fa-check-square" /> Plain Manicure</li>
              <li><i className="fas fa-check-square" /> Single Colour</li>
              <li><i className="fas fa-check-square" /> Nail Art</li>
            </ul>
          </div>
          <div className="book-btn">
            <Link to="/service-nailart">Book Now</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="price-box-wrapper">
          <div className="price-box-head price-response">
            <h3>Paraffin
              Hands</h3>
            <p>From <span>$30.99</span> </p>
          </div>
          <div className="price-menu">
            <ul>
              <li><i className="fas fa-check-square" /> Color/Gel Removal</li>
              <li><i className="fas fa-check-square" /> Plain Manicure</li>
              <li><i className="fas fa-check-square" /> Single Colour</li>
              <li><i className="fas fa-check-square" /> Nail Art</li>
            </ul>
          </div>
          <div className="book-btn">
            <Link to="/service-parahand">Book Now</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- price-section-end --> */}

{/* <!-- service-section-start --> */}
<div className="service-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="service-head-wrapper float-left">
          <div className="service-content">
            <h5>Our Services</h5>
            <h3>What We Offer</h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of
              a page when looking at its
              ayout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
              of letters, as opposed to
              using 'Content here, content here', making it look like readable English.</p>
          </div>
          <div className="service-btn">
            <Link to="#" className="main-btn">
              <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Read More
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="service-box-wrapper">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-mani">
                  <img src="images/service-one.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Manicure</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-padi">
                  <img src="images/service-three.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Pedicure</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-nailart">
                  <img src="images/service-two.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Nail Art</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-parahand">
                  <img src="images/service-four.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Paraffin Hands</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- service-section-end --> */}

{/* <!--testimonial-section-start --> */}
<div className="testimonial-wrapper test-about float-left">
  <div className="testimonial-content-wrapper float-left">
    <div className="container">
      <div className="row">
        <div className="testimonial-head">
          <h5>Testimonial</h5>
          <h3>What our client’s say</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-9">
          <div className="test-slider-wrapper float-left">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="testimonial-box">
                  <i className="fas fa-quote-left" />
                  <div className="testimonial-content">
                    <p>
                      We provide exquisite service with some of the most talented and
                      experienced artists in the industry.
                      My team and I compliment the face using stunning hairdressing
                      techniques, from up-do’s to fishtails.
                      Whether you’re attending a red carpet event or hosting a TV show, my
                      makeup services will ensure you look flawless.
                    </p>
                  </div>
                  <img src="images/testimonial-1.png" alt="img" />
                  <h5>Pamela Adams</h5>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-box">
                  <i className="fas fa-quote-left" />
                  <div className="testimonial-content">
                    <p>
                      Treat yourself to one of our signature services.
                      Our beauty experts will make you look more beautiful and feel better
                      than ever before.
                      The royal treatment for the hands of a queen.
                      Join our membership for extra benefit. Get discount monthly.
                      Charming, bubbly, relaxing atmosphere
                      Extraordinary nail art designs
                    </p>
                  </div>
                  <img src="images/testimonial-2.png" alt="img" />
                  <h5>penter volly</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- testimonial-section-end --> */}

{/* <!-- news-section-start --> */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <Link to="#" className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <!-- news-section-end --> */}
        <Footer />
    </div>
  )
}

export default Service