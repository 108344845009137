import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div>
        <Header />

   {/* first section start here  */}
<div className="float-left banner-wrapper">
  <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-indicators">
      <Link to="#" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1">
        <span>
          <img src="images/slider-mini.png" alt="img" />
        </span>
      </Link>
      <Link to="#" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2">
        <span>
          <img src="images/slider-mini-2.png" alt="img" />
        </span>
      </Link>
      <Link to="#" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3">
        <span>
          <img src="images/slider-mini3.png" alt="img" />
        </span>
      </Link>
      <Link to="#" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={3} aria-label="Slide 4">
        <span>
          <img src="images/slider-mini4.png" alt="img" />
        </span>
      </Link>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img src="images/bg-slider-one.jpg" className="d-block w-100" alt="img" />
        <div className="carousel-caption d-block d-md-block">
          <div className="banner-head">
            <h1 className="fadeInUpShorter appear-animate"> Choose The <span>Best Attitude</span>
              And Services</h1>
            <p className="fadeInUpShorter appear-animation-visible appear-animate">Change Your Nails Look
              With Our Talented Stylists.</p>
            <Link to="/appointment" className="banner-btn fadeInUpShorter appear-animation-visible appear-animate">
              <div className="main-btn-red">
                <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Get
                  An Appointment</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <img src="images/bg-slider-2.jpg" className="d-block w-100" alt="img" />
        <div className="carousel-caption d-block d-md-block">
          <div className="banner-head">
            <h1 className="fadeInUpShorter appear-animate"> Shine That <span>Brightens</span>
              Your Life</h1>
            <p className="fadeInUpShorter appear-animation-visible appear-animate">Change Your Nails Look
              With Our Talented Stylists.</p>
            <Link to="/appointment" className="banner-btn fadeInUpShorter appear-animation-visible appear-animate">
              <div className="main-btn-red">
                <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Get
                  An Appointment</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <img src="images/bg-slider-3.jpg" className="d-block w-100" alt="img" />
        <div className="carousel-caption d-block d-md-block">
          <div className="banner-head">
            <h1 className="fadeInUpShorter appear-animate">Get Your Nails <span>Done by A</span>
              Skilled Nail Artist</h1>
            <p className="fadeInUpShorter appear-animation-visible appear-animate">Change Your Nails Look
              With Our Talented Stylists.</p>
            <Link to="/appointment" className="banner-btn fadeInUpShorter appear-animation-visible appear-animate">
              <div className="main-btn-red">
                <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Get
                  An Appointment</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <img src="images/bg-slider-4.jpg" className="d-block w-100" alt="img" />
        <div className="carousel-caption d-block d-md-block">
          <div className="banner-head">
            <h1 className="fadeInUpShorter appear-animate"> Get a <span>new look</span>
              for your nails</h1>
            <p className="fadeInUpShorter appear-animation-visible appear-animate">Change Your Nails Look
              With Our Talented Stylists.</p>
            <Link to="/appointment" className="banner-btn fadeInUpShorter appear-animation-visible appear-animate">
              <div className="main-btn-red">
                <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Get
                  An Appointment</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</div>
   {/* first section end here  */}





   {/* second section start here  */}


 <div className="popular-wrapper float-left ptb-100 animated wow fadeInUp2" data-wow-delay="0.1s">
  <div className="experience">
    <h5>Extraordinary Experience In Nail Care</h5>
    <h3>Our Nail Salon IS THE MOST POPULAR, CLEAN
      AND RECOMMENDED NAIL SALON</h3>
    <Link to="/about" className="read-btn">
      <div className="main-btn">
        <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Read More
        </span>
      </div>
    </Link>
  </div>
</div>

   {/* second section end here  */}

   {/* service-section-start  */}
<div className="service-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="service-head-wrapper float-left animated wow fade-up">
          <div className="service-content">
            <h5>Our Services</h5>
            <h3>What We Offer</h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of
              a page when looking at its
              ayout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
              of letters, as opposed to
              using 'Content here, content here', making it look like readable English.</p>
          </div>
          <Link to="service-left.html" className="service-btn">
            <div className="main-btn">
              <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Read More
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
    <div className="service-box-wrapper animated wow fade-box">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-mani">
                  <img src="images/service-one.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Manicure</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-padi">
                  <img src="images/service-two.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Pedicure</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-nailart">
                  <img src="images/service-three.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Nail Art</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="service-box">
            <div className="service-img">
              <div className="service-img-hvr">
                <Link to="/service-parahand">
                  <img src="images/service-four.png" alt="img" />
                </Link>
              </div>
              <div className="service-content">
                <h5>Paraffin Hands</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
     {/* service-section-end  */}




     {/* about-section-start  */}
    <div  className="about-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="about-content animated wow fade-up">
          <h5>About US</h5>
          <h3>Why We Are The Best?</h3>
          <p>It is a long established fact that a reader will be distracted by the readable content of a
            page when looking at its layout. The point of using Lorem Ipsum is that it has a
            more-or-less normal distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English</p>
          <div className="about-btn">
            <Link to="/about" className="main-btn-red">
              <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Find Out More
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="about-img animated wow fade-up">
          <img src="images/about-1.png" alt="img" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
     {/* about-section-end  */}



     {/* team-section-start */}
   <div className="team-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="team-head animated wow fade-up">
          <h5>Our Staff</h5>
          <h3>OUR PROFESSIONALS</h3>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-1.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/our-team">
                <h5>Jordan Mike</h5>
              </Link>
              <span>Manager</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-2.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/our-team">
                <h5>KELLEY MILES</h5>
              </Link>
              <span>Director</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-3.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/our-team">
                <h5>Smith Dan</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 col-12">
        <div className="team-box-wrapper animated wow fade-box">
          <div className="team-img">
            <span>
              <img src="images/team-4.png" alt="img" className="img-fluid" />
            </span>
          </div>
          <div className="team-name">
            <div className="team-content">
              <Link to="/our-team">
                <h5>Carolyn Olson</h5>
              </Link>
              <span>Stylist</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    {/* team-section-end */}




     {/* gellary-section-start  */}
  <div>
  <div className="gellary-wrapper float-left">
    <div className="container">
      <div className="row">
        <div className="gellary-head animated wow fade-up">
          <h5>Our Gallery</h5>
          <h3>AN INCREDIBLE Nail Art EXPERIENCE</h3>
        </div>
      </div>
    </div>
  </div>
  
  <div className="gellary-grid-wrapper float-left animated wow fade-box">
    <div className="container">
      <div className="row">
        <div className="column">
          <Link to="images/gellary-1.png" title="image" className="img-link">
            <img src="images/gellary-1.png" alt="img" />
          </Link>
          <Link to="images/gellary-4.png" title="image-4" className="img-link">
            <img src="images/gellary-4.png" alt="img" />
          </Link>
        </div>
        <div className="column">
          <Link to="images/gellary-2.png" title="image-2" className="img-link">
            <img src="images/gellary-2.png" alt="img" />
          </Link>
          <Link to="images/gellary-5.png" title="image-5" className="img-link">
            <img src="images/gellary-5.png" alt="img" className="last-img" />
          </Link>
        </div>
        <div className="column">
          <Link to="images/gellary-3.png" title="image-3" className="img-link">
            <img src="images/gellary-3.png" alt="img" />
          </Link>
          <Link to="images/gellary-6.png" title="image-6" className="img-link">
            <img src="images/gellary-6.png" alt="img" />
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
    {/* gellary-section-end  */}





    {/* testimonial-section-start  */}
<div className="testimonial-wrapper float-left">
  <div className="testimonial-content-wrapper float-left">
    <div className="container">
      <div className="row">
        <div className="testimonial-head animated wow fade-up">
          <h5>Testimonial</h5>
          <h3>What our client’s say</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-9">
          <div className="test-slider-wrapper float-left animated wow fade-box">
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="testimonial-box">
                  <i className="fas fa-quote-left" />
                  <div className="testimonial-content">
                    <p>
                      We provide exquisite service with some of the most talented and
                      experienced artists in the industry.
                      My team and I compliment the face using stunning hairdressing
                      techniques, from up-do’s to fishtails.
                      Whether you’re attending a red carpet event or hosting a TV show, my
                      makeup services will ensure you look flawless.
                    </p>
                  </div>
                  <img src="images/testimonial-1.png" alt="img" />
                  <h5>Pamela Adams</h5>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-box">
                  <i className="fas fa-quote-left" />
                  <div className="testimonial-content">
                    <p>
                      Treat yourself to one of our signature services.
                      Our beauty experts will make you look more beautiful and feel better
                      than ever before.
                      The royal treatment for the hands of a queen.
                      Join our membership for extra benefit. Get discount monthly.
                      Charming, bubbly, relaxing atmosphere
                      Extraordinary nail art designs
                    </p>
                  </div>
                  <img src="images/testimonial-2.png" alt="img" />
                  <h5>Kelley Miles</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* testimonial-section-end  */}






 {/* news-section-start  */}
<div className="news-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="news-head animated wow fade-up">
        <h5>Newsletter</h5>
        <h3>LATEST NAIL TRENDS AND DESIGNS FOR YOU</h3>
      </div>
      <div className="subscribe-form animated wow fade-up">
        <input type="email" placeholder="Enter Your Email" />
        <div className="suscribe-btn">
          <Link to="#" className="main-btn-red">
            <em><i /><i /><i /><i /><i /><i /><i /><i /></em><span>Subscribe Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
{/* news-section-end  */}



{/* blog-section-start  */}
<div className="blog-wrapper float-left">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="blog-head animated wow fade-up">
          <h3>OUR BLOG</h3>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="blog-box-wrapper animated wow fade-box">
          <div className="blog-img">
            <img src="images/staff-1.png" alt="img" className="img-fluid" />
          </div>
          <div className="blog-name">
            <div className="blog-content">
              <p>27 April 2023</p>
              <h5><Link to="/blog-detail">Ultra Glam Gold Nail Polishes
                  for Holiday.</Link></h5>
              <Link to="/blog-detail" className="read-btn">Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="blog-box-wrapper animated wow fade-box">
          <div className="blog-img">
            <img src="images/staff-2-next.png" alt="img" className="img-fluid" />
          </div>
          <div className="blog-name">
            <div className="blog-content">
              <p>22 April 2023</p>
              <h5><Link to="/blog-detail">Coronavirus: 'Confused picture'
                  over beauty salon openings</Link></h5>
              <Link to="/blog-detail" className="read-btn">Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="blog-box-wrapper animated wow fade-box">
          <div className="blog-img">
            <img src="images/staff-3.png" alt="img" className="img-fluid" />
          </div>
          <div className="blog-name">
            <div className="blog-content">
              <p>24 April 2023</p>
              <h5><Link to="/blog-detail">Hair salon meets clothing
                  boutique at new Lehigh</Link></h5>
              <Link to="/blog-detail" className="read-btn">Read More</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* team-section-end  */}
        <Footer />
    </div>
  )
}

export default Home